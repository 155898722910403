.button {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 500;
    outline: none;
    box-shadow: none;

    &[data-size="lg"] {
        padding: 12px 24px;
        font-size: 20px;
        line-height: 24px;
    }

    &[data-size="md"] {
        padding: 10px 20px;
        font-size: 16px;
        line-height: 20px;
    }

    &[data-size="sm"] {
        padding: 6px 16px;
        font-size: 16px;
        line-height: 20px;
    }

    &:first-child {
        &[data-size="lg"] {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        &[data-size="md"] {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        &[data-size="sm"] {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    &:last-child {
        &[data-size="lg"] {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        &[data-size="md"] {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }

        &[data-size="sm"] {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    &:not(:first-child) {
        border-left: none;
    }
}

.prime {
    background-color: #6200EA;
    color: white;

    &:hover {
        background-color: #311B92;
    }

    &:focus-visible {
        background-image: radial-gradient(36.59% 100.8% at 50% 50%, rgba(255,255,255,.3) 99.54%, rgb(255 255 255) 100%);
    }
}

.outlined {
    border: 1.5px solid #6200EA;
    background-color: white;
    color: #6200EA;

    &:hover {
        background-color: rgba(98,0,234,.12);
    }

    &:focus-visible {
        background-image: radial-gradient(36.59% 100.8% at 50% 50%, rgba(98,0,234,.3) 99.54%, rgb(255 255 255) 100%);
    }
}

.text {
    box-sizing: border-box;
    background-color: white;
    color: #6200EA;

    &:hover {
        background-color: rgba(98,0,234,.12);
    }

    &:focus-visible {
        background-image: radial-gradient(36.59% 100.8% at 50% 50%, rgba(98,0,234,.3) 99.54%, rgb(255 255 255) 100%);
    }
}

.default {
    background-color: white;
    border: 2px solid rgba(0,0,0,.12);
    color: rgba(0,0,0,.87);
    padding: 4px 16px;
}

.active {
    background-color: rgba(0,0,0,.24);
    border: 2px solid rgba(0,0,0,.2);
    color: rgba(0,0,0,.87);
}

.disabled {
    background-color: rgba(0,0,0,.12);
    cursor: default;
    color: rgba(0,0,0,.26);
    border-color: rgba(0,0,0,.12);

    &:hover {
        background-color: rgba(0,0,0,.12);
    }

    &:focus-visible {
        background-image: none;
    }
}
